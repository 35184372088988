// extracted by mini-css-extract-plugin
export var awsExpertImg = "PartnersMain-module--awsExpertImg--dee4a";
export var awsExpertImgDiv = "PartnersMain-module--awsExpertImgDiv--961e5";
export var awsMainImage = "PartnersMain-module--awsMainImage--ef998";
export var businessImage = "PartnersMain-module--businessImage--09163";
export var businessItem = "PartnersMain-module--businessItem--3d5ee";
export var businessItemImage = "PartnersMain-module--businessItemImage--34c8d";
export var businessItemMain = "PartnersMain-module--businessItemMain--c1f75";
export var businessMain = "PartnersMain-module--businessMain--cb12b";
export var businessText = "PartnersMain-module--businessText--6fbbc";
export var buttonClasses = "PartnersMain-module--buttonClasses--dbdd3";
export var carouselContainer = "PartnersMain-module--carouselContainer--78109";
export var contactButton = "PartnersMain-module--contactButton--5334e";
export var heroBackgroundCircleLeft = "PartnersMain-module--heroBackgroundCircleLeft--29297";
export var heroBackgroundInnerCircleLeft = "PartnersMain-module--heroBackgroundInnerCircleLeft--cf2a8";
export var knowUs = "PartnersMain-module--knowUs--61a4b";
export var knowUsImage = "PartnersMain-module--knowUsImage--1ce4d";
export var knowUsSubDiv = "PartnersMain-module--knowUsSubDiv--cc605";
export var knowUsText = "PartnersMain-module--knowUsText--d6bdf";
export var partners = "PartnersMain-module--partners--15bf3";
export var partnersBenefits = "PartnersMain-module--partnersBenefits--79b8c";
export var partnersBenefitsCarousel = "PartnersMain-module--partnersBenefitsCarousel--d3d5e";
export var partnersBenefitsInner = "PartnersMain-module--partnersBenefitsInner--344ee";
export var partnersBenefitsItem = "PartnersMain-module--partnersBenefitsItem--a954e";
export var partnersBenefitsItemBusiness = "PartnersMain-module--partnersBenefitsItemBusiness--a8d58";
export var partnersBenefitsItemBusinessTop = "PartnersMain-module--partnersBenefitsItemBusinessTop--cec03";
export var partnersBenefitsTech = "PartnersMain-module--partnersBenefitsTech--d4179";
export var partnersButtonsContainer = "PartnersMain-module--partnersButtonsContainer--48c35";
export var partnersMainSlider = "PartnersMain-module--partnersMainSlider--db5d6";